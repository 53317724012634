.animated-border {
    position: relative;
    padding: 1.5rem;
    border-radius: 12px;
    overflow: hidden;
    border: 4px dashed transparent;
    background-size: 
      20px 2px, 
      20px 2px, 
      2px 20px, 
      2px 20px;
    background-position: 
      0 0, 
      0 100%, 
      0 0, 
      100% 0;
    background-repeat: 
      repeat-x, 
      repeat-x, 
      repeat-y, 
      repeat-y;
    background-image: 
      linear-gradient(to right, #fff 50%, #000 50%), 
      linear-gradient(to right, #fff 50%, #000 50%), 
      linear-gradient(to bottom, #fff 50%, #000 50%), 
      linear-gradient(to bottom, #fff 50%, #000 50%);
    animation: marching-ants-1 1s linear infinite;
}

@keyframes marching-ants-1 {
    0% {
      background-position: 
        0 0, 
         0 100%, 
        0 0, 
        100% 0;
    } 
    100% {
      background-position: 
        40px 0, 
        -40px 100%, 
        0 -40px, 
        100% 40px;
    }
}
