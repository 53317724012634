.upload-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1.5rem; /* Adjusted padding to make the banner shorter */
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1a1a1a;
  border-radius: 12px;
  overflow: hidden;
  border: 4px dashed transparent;
  z-index: 1000;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out, background-color 0.3s ease-in-out;
  opacity: 0;
  transform: translateY(100%);
  box-sizing: border-box;
  background-size: 
    20px 2px, 
    20px 2px, 
    2px 20px, 
    2px 20px;
  background-position: 
    0 0, 
    0 100%, 
    0 0, 
    100% 0;
  background-repeat: 
    repeat-x, 
    repeat-x, 
    repeat-y, 
    repeat-y;
  background-image: 
    linear-gradient(to right, #fff 50%, #000 50%), 
    linear-gradient(to right, #fff 50%, #000 50%), 
    linear-gradient(to bottom, #fff 50%, #000 50%), 
    linear-gradient(to bottom, #fff 50%, #000 50%);
  animation: marching-ants-1 1s linear infinite paused;
}

.upload-banner:hover {
  background-color: #333; /* Lighter gray on hover */
}

.upload-banner:hover, 
.upload-banner.sticky {
  animation-play-state: running;
  opacity: 1;
  transform: translateY(0);
}

@keyframes marching-ants-1 {
  0% {
    background-position: 
      0 0, 
      0 100%, 
      0 0, 
      100% 0;
  } 
  100% {
    background-position: 
      40px 0, 
      -40px 100%, 
      0 -40px, 
      100% 40px;
  }
}

.hidden {
  display: none;
}
